<template>
  <div class="two">
    <oneCol
      style="flex:1;margin-right:20px"
      url="home/HomeGetSzNews"
      :param="{ top: 4, type:15 }"
      text="工作动态"
      type="3"
      more-url="/main?type=1&selected=11&page=1"
    />

    <oneCol
      style="flex:1;margin-right:20px"
      url="home/HomeGetSzNews"
      :param="{ top: 4, type: 8 }"
      text="党员声音"
      type="3"
      more-url="/main?type=1&selected=7&page=1"
    />

    <oneCol
      style="flex:1;"
      url="home/HomeGetSzNews"
      :param="{ top: 4, type: 12 }"
      text="帮扶动态"
      type="3"
      more-url="/main?type=5&selected=0&page=1"
    />

  </div>
</template>

<script>
import oneCol from '../oneCol.vue'
export default {
  components: {
    oneCol
  },
  data() {
    return {
    }
  },

  created() { },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
.two {
  display: flex;
}
.rightContent {
  img {
    width: 100%;
    height: 200px;
  }

  .twoItem {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 199px;
      height: 132px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 8px;
      padding: 31px 0 21px 0;
    }
    .item > div > img {
      margin-right: 10px;
      width: 50px;
      height: 50px;
    }
    .item > div > span {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .item > span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 21px;
    }
  }
}
</style>
