<template>
  <div class="one">
    <news style="margin-right:40px" more-url="/main?type=1&selected=3" :param="{ top: 9, type: 5 }" :text="['先锋模范']" />
    <div class="middle">
      <titleStyle style="margin-bottom:30px" :text="['民意反馈']" />
      <div class="rightContent" style="width:406px">
        <div v-for="(item,index) in dataList" :key="index" class="colCenter click" :class="{mr:index%3!==2}" @click="$go(item.url)">
          <img :src="item.img">
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>

    <div class="right" style="margin-left:40px;flex:1;">
      <titleStyle style="margin-bottom:30px" :text="['办事指南']" />
      <div class="content">
        <div class="top">
          <div class="click" @click="$go(rightList[0].url)">
            <img :src="rightList[0].img">
            <div>{{ rightList[0].name }}</div>
          </div>

          <div class="click" @click="$go(rightList[0].url)">
            <img :src="rightList[0].img">
            <div>{{ rightList[0].name }}</div>
          </div>
        </div>
        <myImg class="click bottomBg" :src="$beforeUrl(ad1.img)" @click="$go(ad1.linkUrl)" />
      </div>
    </div>
  </div>
</template>

<script>
import news from '../oneCol.vue'
import titleStyle from '../titleStyle.vue'
import myImg from '@/components/myImg'
export default {
  components: {
    news, titleStyle, myImg
  },
  data() {
    return {
      dataList: [
        { name: '群众随手拍', url: '/Readily', img: require('@/assets/home/second/1.png') },
        { name: '在线投诉', url: '/ConsultingList?type=在线投诉', img: require('@/assets/home/second/2.png') },
        { name: '留言建议', url: '/AddProposal', img: require('@/assets/home/second/3.png') },
        { name: '在线咨询', url: '/ConsultingList?type=在线咨询', img: require('@/assets/home/second/4.png') },
        { name: '需求对接', url: '/DemandCreate', img: require('@/assets/home/second/5.png') },
        { name: '对话书记', url: '/ConsultingList?type=对话书记', img: require('@/assets/home/second/6.png') },
        { name: '困难帮扶', url: '/CreateDiff', img: require('@/assets/home/second/7.png') },
        { name: '监督举报', url: '/ConsultingList?type=监督举报', img: require('@/assets/home/second/8.png') },
        { name: '信息监管', url: '/ConsultingList?type=信息监管', img: require('@/assets/home/second/9.png') }
      ],
      rightList: [
        { name: '办事指南', url: '/main?type=3&selected=0&page=1', img: require('@/assets/home/second/10.png') },
        { name: '公共服务', url: '/main?type=3&selected=1&page=1', img: require('@/assets/home/second/11.png') }
      ],
      ad1: {}
    }
  },
  watch: {
  },
  created() {
    this.getInfo()
  },

  methods: {
    getInfo() {
      this.$store.dispatch('home/HomeGetSiteAd', { top: 1, position: 'sideBan', tempId: this.$store.getters.module }).then(res => {
        if (res.code === 0 && res.data[0].jsonText) {
          this.ad1 = JSON.parse(res.data[0].jsonText)[0]
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content{
  .top{
    display: flex;
    justify-content: space-between;
    &>div{
      width: 192px;
      height: 120px;
      background: #F8F9FA;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 50px;
      }
      div{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-left: 10px;
      }
    }
  }
  .bottomBg{
    width: 100%;
    height: 260px;
    margin-top: 20px;
  }

}
  .rightContent{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &>div{
      width: 122px;
      height: 120px;
      background: #F8F9FA;
      border-radius: 8px;
      margin-bottom: 20px;
      span{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-top: 12px;
      }
      img{
        width: 40px;
      }

    }
  }

::v-deep{
  .el-carousel__arrow{
    border-radius: unset!important;
    background-color: rgba(0,0,0,0)!important;
  }
  .el-carousel__arrow--right,.el-carousel__arrow--left{
    margin-top: 203px;
  }
  .el-icon-arrow-right:before,.el-icon-arrow-left:before{
    font-size:20px;
    font-weight: 800;
  }
}
.one{
  display: flex;
  background:#fff;
  .lunbo{
    height: 100%;
    position: relative;
    &>div{
      position: absolute;
      bottom: 0;
      left: 0;
      right:0;
      height: 60px;
      background: rgba(0,0,0,.5);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-around;
      &>div:last-child{
        width: 70%;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;

      }
      .page{
          &>span:first-child{
            font-size: 23px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            opacity: .8;
          }
          &>span:last-child{
            font-size:20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            opacity: .8;
          }
        }
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
}

</style>
