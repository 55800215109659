<template>
  <div class="four">
    <news
      style="flex: 1"
      url="home/HomeGetSzNews"
      :param="{ top: 4, index:14 }"
      text="工作动态"
      type="3"
      more-url="/main?type=1&selected=11&page=1"
    />
    <news
      style="flex: 1; margin-left: 20px"
      url="home/HomeGetSzNews"
      :param="{ top: 4, index: 7 }"
      text="党员声音"
      type="3"
      more-url="/main?type=1&selected=7&page=1"
    />
    <news
      style="flex: 1; margin-left: 20px"
      url="home/HomeGetSzNews"
      :param="{ top: 4, index: 11 }"
      text="帮扶动态"
      type="3"
      more-url="/main?type=5&selected=0&page=1"
    />

  </div>
</template>

<script>
import news from '../news.vue'
export default {
  components: {
    news
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>

<style lang="scss" scoped>
.four {
  display: flex;
  height: 460.5px;
}
</style>
