<template>
  <div v-loading="loading">
    <titleStyle more-url="/main?type=2&selected=0&page=1" text="党建视频" />
    <div v-if="videoList.length" class="video">
      <div class="left  click" @click="$go(`/main/detail?id=${videoList[0].id}`)">
        <img class="itemImg" :src="$beforeUrl(videoList[0].videoPoster)">
        <img
          class="startIcon"
          :src="require('@/assets/template/second/startIcon.png')"
        >
        <div class="oneLine text">{{ videoList[0].title }}</div>
      </div>
      <div class="right">
        <div
          v-for="(item, index) in videoList.slice(1)"
          :key="index"
          class="rightItem  click"
          @click="$go(`/main/detail?id=${item.id}`)"
        >
          <img class="itemImg" :src="$beforeUrl(item.videoPoster)">
          <img
            class="startIcon "
            :src="require('@/assets/template/second/startIcon.png')"
          >
          <div class="oneLine text">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div v-else style="flex: 1; font-size: 100px" class="colCenter it">
      <img :src="require('@/assets/home/first/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
import titleStyle from '../titleStyle.vue'
export default {
  components: {
    titleStyle
  },
  data() {
    return {
      videoList: [],
      loading: true
    }
  },

  created() {
    this.$store.dispatch('home/HomeGetVideoNews', { top: 5 }).then((res) => {
      if (res.code === 0) {
        this.videoList = res.data
      }
      this.loading = false
    })
  },

  methods: {}
}
</script>

<style lang="scss" scoped>
.video {
  display: flex;

  .left {
    position: relative;
    height: 426px;
    .itemImg {
      width: 640px;
      height: 426px;
    }
    .startIcon {
      width: 120px;
      height: 120px;
      position: absolute;
      z-index: 1;
      top: 153px;
      left: 260px;
    }
    .text {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 50px;
      background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      line-height: 50px;
      padding: 0 20px;
    }
  }

  .right {
    display: flex;
    flex: 1;
    flex-wrap: wrap;

    .rightItem {
      position: relative;
      margin-left: 20px;
      height: 203px;
      .itemImg {
        width: 310px;
        height: 203px;
      }
      .startIcon {
        width: 96px;
        height: 96px;
        position: absolute;
        z-index: 1;
        top: 53.5px;
        left: 107px;
      }
      .text {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 50px;
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        line-height: 50px;
        padding: 0 20px;
      }
    }
    &>.rightItem:first-child{
      margin-bottom: 20px;
    }
  }
}
</style>
