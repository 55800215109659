<template>
  <div class="one">

    <news v-loading="leftLoading" :data-list="dataList[selected] || []" style="flex:1" :more-url="moreUrl[selected]+'&name='+colList[colIndex].name" :url="url[selected]" :param="param[selected]" :text="textList" />

    <div v-loading="lunboLoading" style="width:700px;margin-left:40px">
      <el-carousel :interval="5000" arrow="always" indicator-position="none" height="466px">
        <el-carousel-item v-for="(item,index) in lunBoList" :key="index">
          <div class="lunbo click" @click="$go(item.linkUrl)">
            <img :src="$beforeUrl(item.img)">
            <div style="padding-left:54px;padding-right:54px">
              <div class="page">
                <span>{{ index+1 }}</span>
                <span>/{{ lunBoList.length }}</span>
              </div>
              <div class="oneLine">{{ item.imgName }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import news from '../components/topContent.vue'
export default {
  components: {
    news
  },
  data() {
    return {
      dataList: [],
      lunBoList: [],
      url: ['home/HomeGetSzNews', 'home/HomeGetSzNews'],
      moreUrl: ['/main?type=1&selected=0&page=1', '/main?type=1&selected=8&page=1'],
      param: [{ top: 10, type: 1 }, { top: 10, type: 2 }],
      titleList: [],
      selected: 0,
      leftLoading: true,
      lunboLoading: true,
      textList: []
    }
  },
  computed: {
    colList() {
      return this.$store.state.app.colList
    },
    colIndex() {
      return this.param[this.selected].type - 1
    }
  },
  watch: {
    selected() {
      this.getInit()
    }
  },
  created() {
    this.getInit()
    this.getLunBo()
  },
  mounted() {
    this.getTextList()
  },

  methods: {
    getInit() {
      if (!this.dataList[this.selected]) {
        this.leftLoading = true
        const newParam = JSON.parse(JSON.stringify(this.param[this.selected]))
        delete newParam.type
        this.$store.dispatch(this.url[this.selected], { ...newParam, ...this.colList[this.colIndex] }).then((res) => {
          if (res.code === 0) {
            this.$parent.titleDist = res.data[0] || {}
            this.titleList[this.selected] = res.data[0] || {}
            this.dataList[this.selected] = res.data.slice(1)
            this.$forceUpdate()
          } else {
            this.$message.error(res.msg || '请求失败,请刷新重试')
          }
          this.leftLoading = false
        })
      } else {
        this.$parent.titleDist = this.titleList[this.selected]
      }
    },
    getLunBo() {
      const top = 10
      const position = 'banner'
      const tempId = this.$store.getters.module
      this.$store.dispatch('home/HomeGetSiteAd', { top, position, tempId }).then(res => {
        if (res.code === 0 && res.data[0].jsonText) {
          this.lunBoList = JSON.parse(res.data[0].jsonText)
        }
        this.lunboLoading = false
      })
    },
    getTextList() {
      this.textList = [this.colList[0].name, this.colList[1].name]
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-carousel__arrow{
    border-radius: unset!important;
    background-color: rgba(0,0,0,0)!important;
  }
  .el-carousel__arrow--right,.el-carousel__arrow--left{
    margin-top: 203px;
  }
  .el-icon-arrow-right:before,.el-icon-arrow-left:before{
    font-size:20px;
    font-weight: 800;
  }
}
.one{
  display: flex;
  background:#fff;
  height: 530px;
  padding: 20px;
  .lunbo{
    height: 100%;
    position: relative;
    &>div{
      position: absolute;
      bottom: 0;
      left: 0;
      right:0;
      height: 60px;
      background: rgba(0,0,0,.5);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-around;
      &>div:last-child{
        width: 70%;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;

      }
      .page{
          &>span:first-child{
            font-size: 23px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            opacity: .8;
          }
          &>span:last-child{
            font-size:20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            opacity: .8;
          }
        }
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
}

</style>
