<template>
  <div v-loading="loading" class="news">
    <titleStyle :more-url="formatMorurl()" :text="colList[param.index].name" />
    <div v-if="type === '2' && dataList.length > 0" class="type2">
      <div class="top click" @click="$go(`/main/detail?id=${dataList[0].id}`)">
        <div class="oneLine title">{{ dataList[0].title }}</div>
        <div class="rich" v-html="$setContent(dataList[0].context)" />
      </div>
      <div v-if="dataList.length" class="bottom" style="flex: 1">
        <div
          v-for="index in Math.ceil(dataList.slice(1, 11).length / 2)"
          :key="index"
          class="line"
        >
          <div class="newsItem-out click" @click="$go(`/main/detail?id=${dataList[index * 2 - 1].id}`)">
            <div class="circle" />
            <div class="newsItem oneLine">
              {{ dataList[index * 2 - 1].title }}
            </div>
          </div>
          <div v-if="index * 2 < dataList.length" class="newsItem-out click" @click="$go(`/main/detail?id=${dataList[index * 2].id}`)">
            <div class="circle" />
            <div class="newsItem oneLine">{{ dataList[index * 2].title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="type === '1' && dataList.length > 0" class="type1">
      <div v-if="dataList[0].thumbnail" class="top click" @click="$go(`/main/detail?id=${dataList[0].id}`)">
        <img :src="$beforeUrl(dataList[0].thumbnail)">
        <div>
          {{ dataList[0].title }}
        </div>
      </div>
      <div v-else class="top2 click" @click="$go(`/main/detail?id=${dataList[0].id}`)">
        <div class="oneLine title">{{ dataList[0].title }}</div>
        <div class="rich" v-html="$setContent(dataList[0].context)" />
      </div>

      <div v-if="dataList.length" class="bottom">
        <div
          v-for="(item, index) in dataList.slice(1)"
          :key="index"
          class="newsItem-out click"
          @click="$go(`/main/detail?id=${item.id}`)"
        >
          <div class="circle" />
          <div class="newsItem oneLine">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="type === '3' && dataList.length > 0" class="type1 type3">
      <div class="top3 click" @click="$go(`/main/detail?id=${dataList[0].id}`)">
        <img :src="$beforeUrl(dataList[0].thumbnail)">
        <div class="oneLine">
          {{ dataList[0].title }}
        </div>
      </div>

      <div v-if="dataList.length" class="bottom">
        <div
          v-for="(item, index) in dataList.slice(1)"
          :key="index"
          class="newsItem-out click"
          @click="$go(`/main/detail?id=${item.id}`)"
        >
          <div class="circle" />
          <div class="newsItem oneLine">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
    <div v-else style="flex: 1; font-size: 100px" class="colCenter it">
      <img :src="require('@/assets/home/first/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
import titleStyle from './titleStyle.vue'
export default {
  components: {
    titleStyle
  },
  props: {
    url: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: '1' // 1正常, 2例外(最顶部那条新闻) 3有点像首页轮播图那样
    },
    param: {
      type: Object,
      default: () => {
        return { top: 6, type: 1 }
      }
    },
    moreUrl: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      dataList: [],
      loading: true
    }
  },
  computed: {
    colList() {
      return this.$store.state.app.colList
    }
  },

  mounted() {},
  created() {
    this.$store.dispatch(this.url, { ...this.param, ...this.colList[this.param.index] }).then((res) => {
      if (res.code === 0) {
        this.dataList = res.data
      } else {
        this.$message.error(res.msg || '请求失败,请刷新重试')
      }
      this.loading = false
    })
  },

  methods: {
    formatMorurl() {
      return this.moreUrl + '&name=' + this.colList[this.param.index].name
    }
  }
}
</script>

<style lang="scss" scoped>
.news {
  flex: 1;
  display: flex;
  flex-direction: column;
  .type2 {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 640px;
    .top {
      height: 142px;
      background: url(../../../../assets/home/first/4.png) no-repeat;
      background-size: 100% 100%;
      padding: 20px 23px 28px 23px;
      margin-top: 20px;
      .title {
        text-align: center;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: var(--theme);
      }
      .rich {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: var(--theme);
        line-height: 21px;
        opacity: 0.8;
        margin-top: 16px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break:break-all;
      }
    }
    .bottom {
      width: 1005;
      .line {
        display: flex;
        justify-content: space-between;
        margin-top: 25.6px;
        .newsItem-out {
          display: flex;
          align-items: center;
          width: 296px;
          .newsItem {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-left: 7px;
            flex: 1;
          }
          .circle {
            width: 4px;
            height: 4px;
            background: var(--theme);
            border-radius: 50%;
          }
        }
      }
    }
  }
  .type1 {
    .top {
      display: flex;
      align-items: center;
      margin-top: 18px;
      height: 120px;
      background: #f5f8fa;

      img {
        width: 180px;
        height: 100%;
      }
      div {
        width: 240px;

        padding: 0 24px 0 15px;
        overflow: hidden;
        word-break:break-all;
        text-overflow: ellipsis;
        display: block;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        line-height: 27px;
      }
    }
    .top2 {
      height: 142px;
      background: #f5f8fa;
      padding: 20px 23px 28px 23px;
      margin-top: 20px;
      width: 418px;
      .title {
        text-align: center;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        padding: 0 5px;
      }
      .rich {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
        margin-top: 16px;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
        word-break:break-all;
      }
    }

    .newsItem-out {
      display: flex;
      align-items: center;
      width: 418px;
      margin-top: 28.5px;
      .newsItem {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-left: 7px;
        flex: 1;
      }
      .circle {
        width: 4px;
        height: 4px;
        background: var(--theme);
        border-radius: 50%;
      }
    }
  }
  .type3 {
    .top3 {
      position: relative;
      img {
        width: 100%;
        height: 278px;
        margin-top: 18px;
      }
      div {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 40px;
        background: #000000;
        opacity: 0.5;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        padding: 0 38px 0 24px;
        display: block;
        line-height: 40px;
      }
    }
  }
}
</style>
