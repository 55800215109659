<template>
  <div v-loading="loading" style="margin-bottom: 40px">
    <titleStyle :more-url="moreUrl+'&name='+colList[colIndex].name" :text="colList[colIndex].name" />

    <div v-if="dataList.length && dataList[0].thumbnail" class="content1">
      <div class="leftImg click" style="margin-right: 20px" @click="$go(`/main/detail?id=${dataList[0].id}`)">
        <img :src="$beforeUrl(dataList[0].thumbnail)">
        <div class="title">
          <div class="inside oneLine">{{ dataList[0].title }}</div>
        </div>
      </div>
      <itemContent :show-emptys="false" :only-text="true" :data-list="dataList.slice(1)" />
    </div>
    <div v-else-if="dataList.length" class="content1">
      <itemContent :only-text="true" style="margin-right:20px" :data-list="dataList.slice(0,4)" />
      <itemContent :show-emptys="false" :only-text="true" :data-list="dataList.slice(4,8)" />
    </div>
    <div v-else style="flex: 1; font-size: 100px" class="colCenter it">
      <img :src="require('@/assets/home/first/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
import titleStyle from './titleStyle.vue'
import itemContent from './itemOnlyText'
export default {
  components: {
    titleStyle,
    itemContent
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    moreUrl: {
      type: String,
      default: ''
    },
    param: {
      type: Object,
      default: () => {
        return { top: 6, type: 1 }
      }
    }
  },
  data() {
    return {
      dataList: [],
      loading: 'true'
    }
  },
  computed: {
    colList() {
      return this.$store.state.app.colList
    },
    colIndex() {
      return this.param.type - 1
    }
  },

  mounted() {},
  created() {
    this.getInit()
  },

  methods: {
    getInit() {
      const newParam = JSON.parse(JSON.stringify(this.param))
      delete newParam.type
      this.$store.dispatch(this.url, { ...newParam, ...this.colList[this.colIndex] }).then((res) => {
        if (res.code === 0) {
          this.dataList = [...res.data, ...res.data]
          !this.dataList.length || (this.dataList[0].thumbnail = '')
        } else {
          this.$message.error(res.msg || '请求失败,请刷新重试')
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content1 {
  display: flex;
  .leftImg {
    position: relative;
    img {
      width: 420px;
      height: 280px;
    }
    .title {
      .inside {
        width: 377px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin: auto;
        height: 42px;
      }

      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      height: 42px;

      &::after {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.8;
        z-index: -1;
        content: "";
        display: block;
      }
    }
  }
}
</style>
