<template>
  <div class="two">
    <news more-url="/main?type=1&selected=8&page=1" style="flex:1;" url="home/HomeGetSzNews" :param="{top:5,index:1}" />
    <news more-url="/main?type=1&selected=4&page=1" style="flex:1;margin-left:20px" url="home/HomeGetSzNews" :param="{top:5,index:2}" />
    <div class="rightContent" style="flex:1;height:390px;margin-left:20px">
      <div v-for="(item,index) in dataList" :key="index" class="colCenter click" :class="{mr:index%3!==2}" @click="$go(item.url)">
        <img :src="item.img">
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import news from '../news.vue'
export default {
  components: {
    news
  },
  data() {
    return {
      dataList: [
        { name: '群众随手拍', url: '/Readily', img: require('@/assets/home/first/1/1.png') },
        { name: '在线投诉', url: '/ConsultingList?type=在线投诉', img: require('@/assets/home/first/1/2.png') },
        { name: '留言建议', url: '/AddProposal', img: require('@/assets/home/first/1/3.png') },
        { name: '在线咨询', url: '/ConsultingList?type=在线咨询', img: require('@/assets/home/first/1/4.png') },
        { name: '需求对接', url: '/DemandCreate', img: require('@/assets/home/first/1/5.png') },
        { name: '对话书记', url: '/ConsultingList?type=对话书记', img: require('@/assets/home/first/1/6.png') },
        { name: '困难帮扶', url: '/CreateDiff', img: require('@/assets/home/first/1/7.png') },
        { name: '监督举报', url: '/ConsultingList?type=监督举报', img: require('@/assets/home/first/1/8.png') },
        { name: '信息监管', url: '/ConsultingList?type=信息监管', img: require('@/assets/home/first/1/9.png') }
      ]
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
.mr{
  margin-right: 21px;
}
  .two{
    display: flex;
  }
  .rightContent{
    display: flex;
    flex-wrap: wrap;
    &>div{
      width: 126px;
      height: 104px;
      background: #FFFFFF;
      border: 1px solid #E0E0E0;
      border-radius: 8px;
      span{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-top: 14px;
      }

    }
  }
</style>
