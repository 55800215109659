<template>
  <div class="two">
    <div class="ItemOut ">
      <div v-for="(item,index) in dataList" :key="index" class="item click" :style="{backgroundImage:`url(${item.img})`}" @click="$go(item.url)">{{ item.name }}</div>
    </div>
    <div class="right click" :style="{backgroundImage:`url(${require('@/assets/template/second/home11icon/11.png')})`}" @click="$go('/main?type=3&selected=1&page=1')">
      <div>公共服务</div>
      <div>机构实现在线办事</div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      dataList: [
        { name: '群众随手拍', url: '/Readily', img: require('@/assets/template/second/home11icon/1.png') },
        { name: '在线投诉', url: '/ConsultingList?type=在线投诉', img: require('@/assets/template/second/home11icon/2.png') },
        { name: '留言建议', url: '/AddProposal', img: require('@/assets/template/second/home11icon/3.png') },
        { name: '在线咨询', url: '/ConsultingList?type=在线咨询', img: require('@/assets/template/second/home11icon/4.png') },
        { name: '需求对接', url: '/DemandCreate', img: require('@/assets/template/second/home11icon/5.png') },
        { name: '困难帮扶', url: '/CreateDiff', img: require('@/assets/template/second/home11icon/6.png') },
        { name: '监督举报', url: '/ConsultingList?type=监督举报', img: require('@/assets/template/second/home11icon/7.png') },
        { name: '信息监管', url: '/ConsultingList?type=信息监管', img: require('@/assets/template/second/home11icon/8.png') },
        { name: '对话书记', url: '/ConsultingList?type=对话书记', img: require('@/assets/template/second/home11icon/9.png') },
        { name: '办事指南', url: '/main?type=3&selected=0&page=1', img: require('@/assets/template/second/home11icon/10.png') }
      ]
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .two{
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    .ItemOut{
      width: 1080px;
      height: 248px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .item{
        width: 200px;
        height: 104px;
        padding: 44px 29px 43px 81px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;;
      }

    }
    .right{
        width: 200px;
        height: 228px;
        padding: 121px 30px 52px 30px ;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        &>div:first-child{
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
        &>div:nth-child(2){
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
        }
      }
  }
</style>
