<template>
  <div>
    <div class="titleStyle">
      <div>
        <div>{{ text }}</div>
        <img :src="require('@/assets/home/first/1.png')">
      </div>
      <div class="click">
        <img :src="require('@/assets/home/first/2.png')">
        <span @click="go">更多 ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    moreUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {
    go() {
      this.$go(this.moreUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
  .titleStyle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom:5px;
    border-bottom: 1px solid #D0A25B;
    &>div{
      display: flex;
      align-items: center;

    }
    &>div:first-child{
      padding-left:10px ;
      background: rgba(208, 162, 91,.1);
      div{
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: var(--theme);
      }
      img{
        width: 34px;
        height: 13px;
        margin-left: 10px;
      }
    }
    &>div:last-child{
      span{
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #C38B32;
      }
      img{
        width: 11px;
        height: 11px;
        margin-right: 4px;
      }
    }

  }
</style>
