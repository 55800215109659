<template>
  <div v-loading="loading" class="oneCol">
    <titleStyle style="margin-bottom:30px" :more-url="moreUrl+'&name='+colList[colIndex].name" :text="[colList[colIndex].name]" />

    <div class="content1">
      <oneItem :data-list="dataList" />
    </div>
  </div>
</template>

<script>
import titleStyle from './titleStyle.vue'
import oneItem from './components/oneItem.vue'
export default {
  components: {
    titleStyle,
    oneItem
  },
  props: {
    text: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: '1' // 1正常, 2例外(最顶部那条新闻) 3有点像首页轮播图那样
    },
    param: {
      type: Object,
      default: () => {
        return { top: 6, type: 1 }
      }
    },
    moreUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dataList: [],
      loading: true
    }
  },
  computed: {
    colList() {
      return this.$store.state.app.colList
    },
    colIndex() {
      return this.param.type - 1
    }
  },
  created() {
    this.getInit()
  },

  methods: {
    getInit() {
      const newParam = JSON.parse(JSON.stringify(this.param))
      delete newParam.type
      this.$store.dispatch('home/HomeGetSzNews', { ...newParam, ...this.colList[this.colIndex] }).then((res) => {
        if (res.code === 0) {
          this.dataList = res.data
        } else {
          this.$message.error(res.msg || '请求失败,请刷新重试')
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.oneCol{
  width: 406px;
}
.content1 {
  display: flex;
  .leftImg {
    position: relative;
    img {
      width: 420px;
      height: 280px;
    }
    .title {
      .inside {
        width: 377px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin: auto;
        height: 42px;
      }

      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      height: 42px;

      &::after {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.8;
        z-index: -1;
        content: "";
        display: block;
      }
    }
  }
}
</style>
