<template>
  <div>
    <div class="titleStyle">
      <div class="topText">
        <div v-for="(item,index) in text" :key="index" :class="{selected:index===selected,click:selected!==index}" @click="change(index)">{{ item }}</div>
      </div>
      <div v-if="moreUrl" class="click more" @click="go">更多 ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: Array,
      default: () => []
    },
    moreUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: 0
    }
  },

  mounted() {

  },

  methods: {
    go() {
      this.$go(this.moreUrl + '&name=' + this.text[this.selected])
    },
    change(index) {
      this.$emit('selected', index)
      this.selected = index
    }
  }
}
</script>

<style lang="scss" scoped>
  .titleStyle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEE;
    .topText{
      display: flex;
      div{
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-right: 81px;
      }
    }
    .selected{
      color: var(--theme)!important;
      padding-bottom: 16px;
      border-bottom: 3px solid var(--theme);
    }
    &>div:last-child{
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }

  }
  .more{
    height: 40px;
  }
</style>
