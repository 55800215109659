<template>
  <div class="oneItems">

    <div v-if="dataList.length && dataList[0].thumbnail">
      <div class="oneTitle click" @click="$go(`/main/detail?id=${dataList[0].id}`)">
        <img v-if="dataList[0].thumbnail" :src="$beforeUrl(dataList[0].thumbnail)">
        <div class="right">
          <div class="title twoLine">{{ dataList[0].title }}</div>
          <div class="content twoLine rich" v-html="$setContent(dataList[0].context)" />
        </div>
      </div>
      <div v-for="(item,index) in dataList.slice(1,7)" :key="index" style="margin-top: 27px;" class="oneItem click" @click="$go(`/main/detail?id=${item.id}`)">
        <div class="circle" />
        <div class="title oneLine">{{ item.title }}</div>
      </div>
    </div>
    <div v-else-if="dataList.length">
      <div class="oneItem click" style="margin-bottom: 34px;" @click="$go(`/main/detail?id=${dataList[0].id}`)">
        <div
          class="circle"
          style="background: #90080E;"
        />
        <div class="title oneLine">{{ dataList[0].title }}</div>
      </div>
      <div v-for="(item,index) in dataList.slice(1,9)" :key="index" class="oneItem click" style="margin-top: 27px;" @click="$go(`/main/detail?id=${item.id}`)">
        <div class="circle" />
        <div class="title oneLine">{{ item.title }}</div>
      </div>
    </div>
    <div v-else-if="showEmpty" style="flex: 1; font-size: 100px" class="colCenter it">
      <img :src="require('@/assets/home/first/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    showEmpty: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  },
  created() {
  }

}
</script>

<style lang="scss" scoped>
.twoLine{
  max-height: 42.22px;
}
.threeLine{
  max-height: 63.33px;
}
  .oneItems{
    width: 420px;
    min-height:440px;

    .oneTitle{
      display: flex;
      img{
        width: 180px;
        height: 120px;
        margin-right: 14px;
      }
      .right{
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        .title{
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: 32px;
          max-height:unset;
        }
      }
    }
    .oneItem{
      display: flex;
      align-items: center;
      overflow: hidden;

      justify-content: space-between;
      &>div{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .title{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        flex:1;
      }
    }
    & .oneItem:last-child{
      &::after{
        display: none;
      }
    }
  }

  .threeLine{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break:break-all;
}

.circle{
  width: 4px;
  height: 4px;
  background: #333333;
  border-radius: 50%;
  margin-right: 9px;
}
</style>
