<template>
  <div class="oneItems">
    <titleStyle :more-url="moreUrl" :text="text" @selected="changeSelected" />
    <div v-if="dataList.length">
      <div v-for="(item,index) in dataList" :key="index" class="oneItem click" @click="$go(`/main/detail?id=${item.id}`)">
        <div class="circle" />
        <div class="title oneLine">{{ item.title }}</div>
        <div class="time">{{ item.createTime.split(' ')[0] }}</div>
      </div>
    </div>
    <div v-else style="flex: 1; font-size: 100px" class="colCenter it">
      <img :src="require('@/assets/home/first/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
import titleStyle from '../titleStyle'
export default {
  components: { titleStyle },
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    text: {
      type: Array,
      default: () => []
    },
    url: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: '1' // 1正常, 2例外(最顶部那条新闻) 3有点像首页轮播图那样
    },
    param: {
      type: Object,
      default: () => {
        return { top: 6, type: 1 }
      }
    },
    moreUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
    changeSelected(index) {
      if (this.$parent.selected === index) return
      this.$parent.selected = index
    }
  }

}
</script>

<style lang="scss" scoped>
  .oneItems{
    overflow: hidden;
    .oneItem{
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-top: 27px;
      justify-content: space-between;
      &>div{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .title{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        flex:1;
      }
      .time{
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        width: max-content;
      }
    }

  }

  .threeLine{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break:break-all;
}
.circle{
  width: 4px;
  height: 4px;
  background: #333333;
  border-radius: 50%;
  margin-right: 9px;
}
</style>
