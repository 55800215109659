<template>
  <div v-loading="loading" style="margin-bottom:40px">
    <titleStyle :more-url="moreUrl+'&name='+colList[colIndex].name" :text="colList[colIndex].name" />

    <div v-if="dataList.length" class="content">
      <itemContent style="margin-right:20px" :data-list="[dataList[0],...dataList.slice(3,6)]" />
      <itemContent style="margin-right:20px" :data-list="[...dataList.slice(1,2),...dataList.slice(6,9)]" />
      <itemContent :data-list="[...dataList.slice(2,3),...dataList.slice(9,12)]" />
    </div>
    <div v-else style="flex: 1; font-size: 100px" class="colCenter it">
      <img :src="require('@/assets/home/first/empty.png')">
      <span>暂无内容</span>
    </div>

  </div>
</template>

<script>
import titleStyle from './titleStyle.vue'
import itemContent from './item'
export default {
  components: {
    titleStyle, itemContent
  },
  props: {
    url: {
      type: String,
      default: ''
    },
    moreUrl: {
      type: String,
      default: ''
    },
    param: {
      type: Object,
      default: () => {
        return { top: 6, type: 1 }
      }
    }
  },
  data() {
    return {
      dataList: [],
      loading: true
    }
  },
  computed: {
    colList() {
      return this.$store.state.app.colList
    },
    colIndex() {
      return this.param.type - 1
    }
  },

  mounted() {

  },
  created() {
    this.getInit()
  },

  methods: {
    getInit() {
      const newParam = JSON.parse(JSON.stringify(this.param))
      delete newParam.type
      this.$store.dispatch(this.url, { ...newParam, ...this.colList[this.colIndex] }).then((res) => {
        if (res.code === 0) {
          this.dataList = res.data
          this.$parent.$parent.titleDist = res.data[0] // 第一条是首页的标题
          this.dataList = res.data.slice(1) // 截取第一条后的数据
        } else {
          this.$message.error(res.msg || '请求失败,请刷新重试')
        }
        this.loading = false
      })
    }
  }

}
</script>

<style lang="scss" scoped>
  .content{
    display: flex;

  }
</style>
