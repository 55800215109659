<template>
  <div class="oneItems">
    <div v-if="dataList.length">
      <div class="oneTitle click" :class="{bottomBorder:!dataList[0].thumbnail}" @click="$go(`/main/detail?id=${dataList[0].id}`)">
        <img v-if="dataList[0].thumbnail" :src="$beforeUrl(dataList[0].thumbnail)">
        <div class="right">
          <div class="title oneLine">{{ dataList[0].title }}</div>
          <div :class="`content ${dataList[0].thumbnail ? 'twoLine':'threeLine'}`" class="rich" v-html="$setContent(dataList[0].context)" />
        </div>
      </div>
      <div v-for="(item,index) in dataList.slice(1)" :key="index" class="oneItem click" @click="$go(`/main/detail?id=${item.id}`)">
        <img :src="require('@/assets/template/second/1.png')">
        <div class="title oneLine">{{ item.title }}</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {

    }
  },
  created() {
    // this.dataList[0].thumbnail = ''
  }

}
</script>

<style lang="scss" scoped>
.twoLine{
  max-height: 42.22px;
}
.threeLine{
  max-height: 63.33px;
}
.bottomBorder{
  position: relative;
  padding-bottom: 19px;
  &::after{
  background: linear-gradient(to right,#D41011,#fff) repeat;
  background-size: 7px 1px;
  opacity: .2;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  display: block;
  height: 1px;
  z-index: 1;
}
}
  .oneItems{
    width: 420px;
    min-height: 280px;

    .oneTitle{
      display: flex;
      img{
        width: 195px;
        height: 130px;
        margin-right: 10px;
      }
      .right{
        overflow: hidden;
        flex: 1;
        .title{
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
        }
        .content{
          margin-top: 19px;
        }
      }
    }
    .oneItem{
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-top: 19px;
      padding-bottom: 20px;
      position: relative;
      &::after{
        background: linear-gradient(to right,#D41011,#fff) repeat;
        background-size: 7px 1px;
        opacity: .2;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        content: "";
        display: block;
        height: 1px;
        z-index: 1;
      }
      img{
        width: 11px;
        height: 19px;
        margin-right: 11px;
      }
      &>div{
        flex: 1;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }
    & .oneItem:last-child{
      &::after{
        display: none;
      }
    }
  }

  .threeLine{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break:break-all;
}
</style>
