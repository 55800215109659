<template>
  <div class="one">

    <div v-loading="loading" style="flex:1">
      <el-carousel :interval="5000" arrow="always" indicator-position="none" height="500px">
        <el-carousel-item v-for="(item,index) in dataList" :key="index">
          <div class="lunbo click" @click="$go(item.linkUrl)">
            <img :src="$beforeUrl(item.img)">
            <div>
              <div class="oneLine" style="max-width:661px">{{ item.imgName }}</div>
              <div class="page">
                <span>{{ index+1 }}</span>
                <span>/{{ dataList.length }}</span>
              </div>

            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <news style="flex:1;margin-top:40px;" more-url="/main?type=1&selected=0&page=1" url="home/HomeGetSzNews" :param="{top:13,type:1}" />
  </div>
</template>

<script>
import news from '../topNew.vue'
export default {
  components: {
    news
  },
  data() {
    return {
      dataList: [
      ],
      loading: true
    }
  },

  mounted() {

  },
  created() {
    this.getInfo()
  },

  methods: {
    getInfo() {
      const top = 10
      const position = 'banner'
      const tempId = this.$store.getters.module
      this.$store.dispatch('home/HomeGetSiteAd', { top, position, tempId }).then(res => {
        if (res.code === 0 && res.data[0].jsonText) {
          this.dataList = JSON.parse(res.data[0].jsonText)
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-carousel__arrow{
    height: 60px!important;
    width:60px!important;
    background-color: rgba(31,45,61,0.55)!important;
  }
  .el-icon-arrow-right:before,.el-icon-arrow-left:before{
    font-size: 30px;
  }
  .el-carousel__arrow--right{
    right: 30px;
  }
  .el-carousel__arrow--left{
    left: 30px;
  }
}
.one{
  display: flex;
  flex-direction: column;
  .lunbo{
    height: 100%;
    position: relative;
    &>div{
      position: absolute;
      bottom: 0;
      left: 0;
      right:0;
      height: 60px;

      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      &::after{
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.5;
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        height: 60px;
        right: 0;
        z-index: 0;

      }
      &>div:first-child{
        width: 85%;
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        text-align: center;
        z-index: 9;
        color: #FFFFFF;

      }
      .page{
        position: absolute;
        z-index: 99;
            left: 30px;
          &>span:first-child{
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: var(--theme);
            opacity: .5;
          }
          &>span:last-child{
            font-size:20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            opacity: .5;

          }
        }
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
}

</style>
