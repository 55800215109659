<template>
  <div class="two">
    <news
      style="flex: 1"
      url="home/HomeGetSzNews"
      :param="{ top: 5,index: 3 }"
      text="党建动态"
      more-url="/main?type=1&selected=6&page=1"
    />
    <news
      style="flex: 1; margin-left: 20px"
      url="home/HomeGetSzNews"
      :param="{ top: 5,index:12 }"
      text="会议纪要"
      more-url="/main?type=1&selected=2&page=1"
    />
    <div class="rightContent" style="flex: 1; height: 390px; margin-left: 20px">
      <img class="click" :src="$beforeUrl(ad1.img)" @click="$go(ad1.linkUrl)">
      <div class="twoItem">
        <div class="item click" @click="$go(`/main?type=3&selected=0&page=1`)">
          <div class="center">
            <img :src="require('@/assets/home/first/1/10.png')">
            <span>办事指南</span>
          </div>
          <span>为群众服务简化办事流程</span>
        </div>

        <div class="item click" @click="$go(`/main?type=3&selected=1&page=1`)">
          <div class="center">
            <img :src="require('@/assets/home/first/1/11.png')">
            <span>公共服务</span>
          </div>
          <span>机构实现在线办事</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import news from '../news.vue'
export default {
  components: {
    news
  },
  data() {
    return {
      ad1: {}
    }
  },

  created() { this.getInfo() },

  methods: {
    getInfo() {
      this.$store.dispatch('home/HomeGetSiteAd', { top: 1, position: 'sideBan', tempId: this.$store.getters.module }).then(res => {
        if (res.code === 0 && res.data[0].jsonText) {
          this.ad1 = JSON.parse(res.data[0].jsonText)[0]
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.two {
  display: flex;
}
.rightContent {
  img {
    width: 100%;
    height: 200px;
  }

  .twoItem {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 199px;
      height: 132px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 8px;
      padding: 31px 0 21px 0;
    }
    .item > div > img {
      margin-right: 10px;
      width: 50px;
      height: 50px;
    }
    .item > div > span {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .item > span {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 21px;
    }
  }
}
</style>
