<template>
  <div class="two">
    <news style="flex:1;" more-url="/main?type=1&selected=10&page=1" url="home/HomeGetSzNews" :param="{top:5,index:8}" text="红色文艺" />
    <news style="flex:1;margin-left:20px" more-url="/main?type=1&selected=12&page=1" url="home/HomeGetSzNews" :param="{top:5,index:13}" text="志愿活动" />
    <news style="flex:1;margin-left:20px" more-url="/main?type=5&selected=1&page=1" url="home/HomeGetSzNews" :param="{top:5,index:10}" text="帮扶政策" />
  </div>
</template>

<script>
import news from '../news.vue'
export default {
  components: {
    news
  },
  data() {
    return {
    }
  },

  mounted() {

  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
.mr{
  margin-right: 21px;
}
  .two{
    display: flex;
    height: 390px;
  }
</style>
