<template>
  <div v-loading="loading" style="margin-bottom: 40px">
    <titleStyle :more-url="moreUrl+'&name='+colList[colIndex].name" :text="colList[colIndex].name" />

    <div class="content1">
      <itemContent :data-list="dataList" />
    </div>
  </div>
</template>

<script>
import titleStyle from './titleStyle.vue'
import itemContent from './itemOnlyText'
export default {
  components: {
    titleStyle,
    itemContent
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    moreUrl: {
      type: String,
      default: ''
    },
    param: {
      type: Object,
      default: () => {
        return { top: 6, type: 1 }
      }
    }
  },
  data() {
    return {
      dataList: [],
      loading: true
    }
  },
  computed: {
    colList() {
      return this.$store.state.app.colList
    },
    colIndex() {
      return this.param.type - 1
    }
  },

  mounted() {},
  created() {
    this.getInit()
  },

  methods: {
    getInit() {
      const newParam = JSON.parse(JSON.stringify(this.param))
      delete newParam.type
      this.$store.dispatch(this.url, { ...newParam, ...this.colList[this.colIndex] }).then((res) => {
        if (res.code === 0) {
          this.dataList = res.data
        } else {
          this.$message.error(res.msg || '请求失败,请刷新重试')
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content1 {
  display: flex;
  .leftImg {
    position: relative;
    img {
      width: 420px;
      height: 280px;
    }
    .title {
      .inside {
        width: 377px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin: auto;
        height: 42px;
      }

      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      height: 42px;

      &::after {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.8;
        z-index: -1;
        content: "";
        display: block;
      }
    }
  }
}
</style>
