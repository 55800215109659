<template>
  <div class="home">
    <div class="home-title oneLine">{{ titleDist.title }}</div>

    <textRound style="margin-bottom: 24px" />

    <div style="width: 1300px; margin: 0 auto;">
      <one />
      <two />
      <myImg
        class="ad1 click"
        :src="$beforeUrl(ad1.img)"
        @click.native="$go(ad1.linkUrl)"
      />
      <three />

      <four style="margin-bottom: 39px" />
      <myImg
        class="ad2 click imgTop"
        :src="$beforeUrl(ad2.img)"
        @click.native="$go(ad2.linkUrl)"
      />
      <five style="margin-bottom: 39px" />
      <six style="margin-bottom: 39px" />

      <myImg
        class="ad2 click"
        :src="$beforeUrl(ad3.img)"
        @click.native="$go(ad3.linkUrl)"
      />

      <seven style="margin-bottom: 39px" />

      <myImg
        class="ad2 click"
        :src="$beforeUrl(ad4.img)"
        @click.native="$go(ad4.linkUrl)"
      />

      <eight />

      <div style="height:40px" />
    </div>

    <footer class="colCenter">
      <div>
        <a href="http://beian.miit.gov.cn/" target="_blank">粤ICP备17074671号-17</a>
      </div>
      <div>智慧党建版 权 所 有 ，未 经 书 面 授 权 禁 止 使 用</div>
      <div>Copyright © 1997-2021 by zhihuidanjian. all rights reserved</div>
    </footer>
  </div>
</template>

<script>
import textRound from './textRound.vue'
import one from './page/a.vue'
import two from './page/b.vue'
import three from './page/c.vue'
import four from './page/d.vue'
import five from './page/e.vue'
import six from './page/f.vue'
import seven from './page/g.vue'
import eight from './page/h.vue'
import myImg from '@/components/myImg'
export default {
  components: { textRound, one, two, three, four, five, six, seven, myImg, eight },

  data() {
    return {
      ad1: {},
      ad2: {},
      ad3: {},
      ad4: {},
      titleDist: {
        title:
          '开启人类高质量发展新征程，习近平提出四点倡开启人类高质量发展新征程，习近平提出四点倡议…'
      }
    }
  },

  mounted() {},
  created() {
    this.getAd1()
    this.getAd2()
    this.getAd3()
    this.getAd4()
  },

  methods: {
    getAd1() {
      this.$store
        .dispatch('home/HomeGetSiteAd', {
          top: 1,
          position: 'langBan',
          tempId: this.$store.getters.module
        })
        .then((res) => {
          if (res.code === 0 && res.data[0].jsonText) {
            this.ad1 = JSON.parse(res.data[0].jsonText)[0]
          }
        })
    },
    getAd2() {
      this.$store
        .dispatch('home/HomeGetSiteAd', {
          top: 1,
          position: 'center1Ban',
          tempId: this.$store.getters.module
        })
        .then((res) => {
          if (res.code === 0 && res.data[0].jsonText) {
            this.ad2 = JSON.parse(res.data[0].jsonText)[0]
          }
        })
    },
    getAd3() {
      this.$store
        .dispatch('home/HomeGetSiteAd', {
          top: 1,
          position: 'center2Ban',
          tempId: this.$store.getters.module
        })
        .then((res) => {
          if (res.code === 0 && res.data[0].jsonText) {
            this.ad3 = JSON.parse(res.data[0].jsonText)[0]
          }
        })
    },
    getAd4() {
      this.$store
        .dispatch('home/HomeGetSiteAd', {
          top: 1,
          position: 'footBan',
          tempId: this.$store.getters.module
        })
        .then((res) => {
          if (res.code === 0 && res.data[0].jsonText) {
            this.ad4 = JSON.parse(res.data[0].jsonText)[0]
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  margin: auto;
  .home-title {
    width: 733px;
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    margin-top: 30px;
    margin-bottom: 23px;
    margin-left: auto;
    margin-right: auto;
  }
  &::after {
    position: absolute;
    content: "";
    display: block;
    top: 60px;
    left: 0;
    right: 0;
    background-image: url(../../../../assets/navTop/second/bg2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 903px;
    z-index: -2;
  }
}
.ad1 {
  width: 1300px;
  height: 200px;
  margin-top: 40px;
  margin-bottom: 30px;
}
.ad2 {
  width: 1300px;
  height: 200px;
  margin-bottom: 30px;
}
footer {
  width: 100%;
  height: 196px;
  background: var(--theme);
  line-height: 38px;
  div {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
