<template>
  <div v-loading="loading" class="leftImg">
    <titleStyle style="margin-bottom:30px" :more-url="moreUrl[selected] +'&name='+colList[colIndex].name" :text="textList" @selected="changeSelected" />

    <div v-if="itemList.length && itemList[0].thumbnail" class="content1">
      <div class="leftItem click" @click="$go(`/main/detail?id=${itemList[0].id}`)">
        <div class="oneLine text" style="margin-bottom:17px">{{ itemList[0].title }}</div>
        <img :src="$beforeUrl(itemList[0].thumbnail)" style="margin-bottom:10px">
        <div class="threeLine rich" style="max-height:90px" v-html="$setContent(itemList[0].context)" />
      </div>
      <oneItem :show-empty="false" style="width:406px" :data-list="itemList.slice(1)" />
    </div>
    <div v-else-if="itemList.length" class="content1 content2">
      <oneItem :show-empty="false" style="width:406px" :data-list="itemList.slice(0,9)" />
      <oneItem :show-empty="false" style="width:406px" :data-list="itemList.slice(9,18)" />
    </div>
    <div v-else-if="!loading" style="flex: 1; font-size: 100px" class="colCenter it">
      <img :src="require('@/assets/home/first/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
import titleStyle from './titleStyle.vue'
import oneItem from './components/oneItem.vue'
export default {
  components: {
    titleStyle,
    oneItem
  },
  props: {
    param: {
      type: Array,
      default: () => []
    },
    moreUrl: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dataList: [],
      selected: 0,
      loading: true,
      textList: []
    }
  },
  computed: {
    itemList() {
      return this.dataList[this.selected] || []
    },
    colList() {
      return this.$store.state.app.colList
    },
    colIndex() {
      return this.param[this.selected].type - 1
    }
  },

  mounted() {
    this.getTextList()
  },
  created() {
    this.getInit()
  },

  methods: {
    getInit() {
      if (!this.dataList[this.selected]) {
        this.loading = true
        const newParam = JSON.parse(JSON.stringify(this.param[this.selected]))
        delete newParam.type
        this.$store.dispatch('home/HomeGetSzNews', { ...newParam, ...this.colList[this.colIndex] }).then((res) => {
          if (res.code === 0) {
            this.$set(this.dataList, this.selected, res.data)
          } else {
            this.$message.error(res.msg || '请求失败,请刷新重试')
          }
          this.loading = false
        })
      }
    },
    changeSelected(index) {
      this.selected = index
      this.getInit()
    },
    getTextList() {
      this.param.forEach(item => {
        this.textList.push(this.colList[item.type - 1].name)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content2{
  justify-content: space-between;
}
.leftImg{
  width: 852px;
}
.leftItem{
  width: 406px;
  margin-right: 40px;
  .text{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
  }
  img{
    width: 406px;
    height: 270px;
  }
}

  .threeLine{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break:break-all;
}
.content1 {
  display: flex;
  .leftImg {
    position: relative;
    .title {
      .inside {
        width: 377px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
        margin: auto;
        height: 42px;
      }

      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      height: 42px;

      &::after {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.8;
        z-index: -1;
        content: "";
        display: block;
      }
    }
  }
}
</style>
