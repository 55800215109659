<template>
  <div class="six">
    <div v-loading="videoLoading" style="height:511.5px;width:857px;">
      <titleStyle text="党建视频" more-url="/main?type=2&selected=0&page=1" />
      <div class="videoList">
        <div v-for="(item,index) in videoList" :key="index" class="click" @click="$go(`/main/detail?id=${item.id}`)">
          <div>
            <img :src="$beforeUrl(item.videoPoster)">
            <img class="video" :src="require('@/assets/home/first/1/12.png')">
          </div>
          <div class="oneLine text">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <news more-url="/main?type=1&selected=9&page=1" style="flex:1;margin-left:20px" url="home/HomeGetSzNews" :param="{top:8,index:6}" text="工作交流" />
  </div>
</template>

<script>
import news from '../news.vue'
import titleStyle from '../titleStyle.vue'
export default {
  components: {
    news, titleStyle
  },
  data() {
    return {
      videoList: [],
      videoLoading: true
    }
  },

  mounted() {

  },
  created() {
    this.$store.dispatch('home/HomeGetVideoNews', { top: 6 }).then(res => {
      if (res.code === 0) {
        this.videoList = res.data
      }
      this.videoLoading = false
    })
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
.six{
  display: flex;
}
.videoList{
  display: flex;
  flex-wrap: wrap;
  &>div:nth-child(3n){
    margin-right: unset!important;
  }
  &>div{
    margin-top: 20.75px;
    width: 275px;
    margin-right: 16px;
    &>div:first-child{
      position: relative;
      &>img:first-child{
        height: 183px;
        width: 100%;
      }
      &>img:last-child{
        height:46px;
        width: 46px;
        position: absolute;
        left: 20px;
        bottom: 20px;
      }

    }
  }
        .text{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin-top: 12px;
      }
}
</style>
