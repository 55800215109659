<template>
  <div class="one">

    <div v-loading="lunboLoading" style="flex:1">
      <el-carousel :interval="5000" arrow="always" indicator-position="none" height="424px">
        <el-carousel-item v-for="(item,index) in dataList" :key="index">
          <div class="lunbo click" @click="$go(item.linkUrl)">
            <img :src="$beforeUrl(item.img)">
            <div>
              <div class="oneLine">{{ item.imgName }}</div>
              <div class="page">
                <span>{{ index+1 }}</span>
                <span>/{{ dataList.length }}</span>
              </div>

            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <news style="flex:1;margin-left:24px" type="2" more-url="/main?type=1&selected=0&page=1" url="home/HomeGetSzNews" :param="{top:11,index:0}" />
  </div>
</template>

<script>
import news from '../news.vue'
export default {
  components: {
    news
  },
  data() {
    return {
      dataList: [
      ],
      lunboLoading: true
    }
  },

  mounted() {

  },
  created() {
    this.getInfo()
  },

  methods: {
    getInfo() {
      const top = 10
      const position = 'banner'
      const tempId = this.$store.getters.module
      this.$store.dispatch('home/HomeGetSiteAd', { top, position, tempId }).then(res => {
        if (res.code === 0 && res.data[0].jsonText) {
          this.dataList = JSON.parse(res.data[0].jsonText)
        }
        this.lunboLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-carousel__arrow{
    border-radius: unset!important;
    height: 60px!important;
    width:40px!important;
    background-color: rgba(31,45,61,0.55)!important;
  }
}
.one{
  display: flex;
  .lunbo{
    height: 100%;
    position: relative;
    &>div{
      position: absolute;
      bottom: 0;
      left: 0;
      right:0;
      height: 60px;
      background: rgba(0,0,0,.5);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-around;
      &>div:first-child{
        width: 85%;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;

      }
      .page{
          &>span:first-child{
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: var(--theme);
            opacity: .5;
          }
          &>span:last-child{
            font-size:15px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            opacity: .5;
          }
        }
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
}

</style>
