<template>
  <div style="margin-bottom:20px">
    <div class="titleStyle">
      <div class="first">
        <div class="circle"><div class="insideCircle" /></div>
        <div class="text">{{ text }}</div>
      </div>
      <div class="click">
        <span @click="go">更多 ></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    moreUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

    }
  },

  mounted() {

  },

  methods: {
    go() {
      this.$go(this.moreUrl)
    }
  }
}
</script>

<style lang="scss" scoped>
  .titleStyle{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background:linear-gradient(to right,#f3cccc 20%,#fff,#fff);
    &>div{
      display: flex;
      align-items: center;

    }
    .first{
      padding-left:10px ;
      height: 51px;

      .text{
        margin-left: 7px;
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .circle{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color:var(--theme);
        padding: 4px;
        .insideCircle{
          width: 8px;
          height:8px;
          background: #f3cccc;
          border-radius: 50%;
        }
      }
    }
    &>div:last-child{
      span{
       font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
      }
    }

  }
</style>
