<template>
  <div v-loading="videoLoading">
    <titleStyle style="margin-bottom:29px;" :text="['党建视频']" more-url="/main?type=2&selected=0&page=1" />
    <div v-if="videoList.length" class="video">
      <div class="right">
        <div
          v-for="(item, index) in videoList"
          :key="index"
          class="rightItem  click"
          @click="$go(`/main/detail?id=${item.id}`)"
        >
          <img class="itemImg" :src="$beforeUrl(item.videoPoster)">
          <img
            class="startIcon "
            :src="require('@/assets/home/second/startIcon.png')"
          >
          <div class="twoLine text" style="margin-top:7px">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div v-else style="flex: 1; font-size: 100px" class="colCenter it">
      <img :src="require('@/assets/home/first/empty.png')">
      <span>暂无内容</span>
    </div>
  </div>
</template>

<script>
import titleStyle from '../titleStyle.vue'
export default {
  components: {
    titleStyle
  },
  data() {
    return {
      videoList: [],
      videoLoading: true
    }
  },
  watch: {
  },
  created() {
    this.$store.dispatch('home/HomeGetVideoNews', { top: 6 }).then((res) => {
      if (res.code === 0) {
        this.videoList = res.data
      }
      this.videoLoading = false
    })
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
.video {
  display: flex;

  .right {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;

    .rightItem {
      position: relative;
      margin-bottom: 50px;
      .itemImg {
        width: 406px;
        height: 270px;
      }
      .startIcon {
        width: 74px;
        height: 74px;
        position: absolute;
        z-index: 1;
        top: 98px;
        left: 166px;
      }
      .text {
        width: 406px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
      }
    }
    &>.rightItem:first-child{
      margin-bottom: 20px;
    }
  }
}
</style>
