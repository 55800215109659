<template>
  <div class="navTop">
    <div>
      <img style="width:77px;height:21px" :src="require('@/assets/home/first/3.png')">
      <div id="scroll_div" style="flex:1">
        <div id="scroll_begin" :class="{minWidth:minWidth}">
          <div v-for="(item,index) in textList" :key="index" @click="$go(`/main/detail?id=${item.id}`)">{{ item.title }}</div>
        </div>
        <div id="scroll_end" :class="{minWidth:minWidth}">
          <div v-for="(item,index) in textList" :key="index" @click="$go(`/main/detail?id=${item.id}`)">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      textList: [
        { title: '·中共中央组织部向全国共产党员和党务工作者致以节日祝贺1' },
        { title: '·中共中央组织部向全国共产党员和党务工作者致以节日祝贺2' },
        { title: '·中共中央组织部向全国共产党员和党务工作者致以节日祝贺3' },
        { title: '·中共中央组织部向全国共产党员和党务工作者致以节日祝贺4' }
      ],
      minWidth: false
    }
  },

  mounted() {

  },
  created() {
    this.getTextList()
  },

  methods: {
    ScrollImgLeft() {
      const speed = 50
      let MyMar = null
      const scroll_begin = document.getElementById('scroll_begin')

      if (scroll_begin.offsetWidth < 1203) { // 解决公告长度过短的问题
        this.minWidth = true
      } else {
        this.minWidth = false
      }

      const scroll_end = document.getElementById('scroll_end')
      const scroll_div = document.getElementById('scroll_div')
      function Marquee() {
        if ((scroll_end.offsetWidth - scroll_div.scrollLeft) <= 0) {
          scroll_div.scrollLeft -= scroll_begin.offsetWidth
        } else {
          scroll_div.scrollLeft += 1.5
        }
      }
      MyMar = setInterval(Marquee, speed)

      scroll_div.onmouseover = function() {
        clearInterval(MyMar)
      }
      scroll_div.onmouseout = function() {
        MyMar = setInterval(Marquee, speed)
      }
    },
    getTextList() {
      this.$store.dispatch('home/HomeGetNotice', { top: 7 }).then(res => {
        if (res.code === 0) {
          this.textList = res.data
          this.$nextTick(() => {
            this.ScrollImgLeft()
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    .navTop{
    height: 40px;
    background: #F6F8FA;
    &>div{
      width: 1300px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      height: 100%;
      #scroll_div,#scroll_begin,#scroll_end{
        display: flex;
        align-items: center;
        height: 100%;

      }
      #scroll_begin,#scroll_end{
        &>div{
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          padding-right: 29px;
          cursor: pointer;
        }
      }
      #scroll_div{
        margin-left: 20px;
        overflow: hidden;
        white-space: nowrap;
      }

    }
  }

  .minWidth{
    min-width: 100%;
  }
</style>
