<template>
  <div>
    <div class="one">
      <leftImg style="flex:1" :more-url="['/main?type=1&selected=5','/main?type=4&selected=0','/main?type=1&selected=11&page=1']" :param="[{top:18,type: 6},{ top: 18, type: 10 },{ top: 18, type: 15 }]" :text="['办事流程','三务公开','工作动态']" />
      <news style="margin-left:40px" more-url="/main?type=1&selected=7&page=1" :param="{ top: 9, type: 8 }" :text="['党员声音']" />
    </div>
    <div class="one">
      <news style="margin-right:40px" more-url="/main?type=5&selected=0&page=1" :param="{ top: 9, type: 12 }" :text="['帮扶动态']" />
      <leftImg style="flex:1" :more-url="['/main?type=1&selected=10&page=1','/main?type=1&selected=12&page=1','/main?type=5&selected=1&page=1','/main?type=1&selected=9&page=1']" :param="[{top:18,type:9},{ top: 18, type: 14 },{ top: 18, type: 11 },{ top: 18, type: 7 }]" :text="['红色文艺','志愿活动','帮扶政策','工作交流']" />

    </div>
  </div>
</template>

<script>
import news from '../oneCol.vue'
import leftImg from '../leftImg.vue'
export default {
  components: {
    news, leftImg
  },
  data() {
    return {
      dataList: []
    }
  },
  watch: {
  },
  created() {
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-carousel__arrow{
    border-radius: unset!important;
    background-color: rgba(0,0,0,0)!important;
  }
  .el-carousel__arrow--right,.el-carousel__arrow--left{
    margin-top: 203px;
  }
  .el-icon-arrow-right:before,.el-icon-arrow-left:before{
    font-size:20px;
    font-weight: 800;
  }
}
.one{
  display: flex;
  background:#fff;
  .lunbo{
    height: 100%;
    position: relative;
    &>div{
      position: absolute;
      bottom: 0;
      left: 0;
      right:0;
      height: 60px;
      background: rgba(0,0,0,.5);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-around;
      &>div:last-child{
        width: 70%;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;

      }
      .page{
          &>span:first-child{
            font-size: 23px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            opacity: .8;
          }
          &>span:last-child{
            font-size:20px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            opacity: .8;
          }
        }
    }
    img{
      width: 100%;
      height: 100%;
    }
  }
}

</style>
